/*** Import / Initialisation des variables ***/

	/* Conf */
	@import '../global/settings';

/********************************************************************************/
/********************************* BLOC_ETUDIANT ********************************/
/********************************************************************************/

	#etudiant {padding-bottom:5rem; padding-left:5rem;}
	#etudiant p:not(.btn_lien), #etudiant h2, #etudiant h3 {text-align:left;}
	#etudiant::before {content:''; position:absolute; left:0; top:0; width:100vw; height:100%; background-color:$yellow; z-index:-1;}
	#etudiant.bloc_home .titre_bloc .bloc_home_title {font-size:3em;}
	#etudiant.bloc_home .titre_bloc h2.bloc_home_title {text-transform:none;}
	#etudiant.bloc_home .bloc_home_subtitle {font-size:1.5em;}

	/*** Questions ***/
		#etudiant #questions .content {text-align:center; margin-bottom:3.5rem;}
	
	/*** Profil ***/
		#profil .titre_bloc {margin-bottom:3.3rem;}
		#profil .content form {padding:0;}
		#profil .content .ligne_form {margin-bottom:2.5rem;}
		#profil .content select {font-size:1.5em; color:$cim_medium_gray; @include select_profil_bg($dark_yellow, $full_black);background-size:1.2rem auto, 45rem auto; background-position:right calc(1.6rem - 5.7rem) center, right -5.7rem center; border-color:$cim_black;}
		#profil .content form option {font-size:inherit;}
		#profil .content form option[disable] {color:$full_black;}
		#profil .content form .btn_submit {float: none; text-align:center; margin-bottom:0; padding-top:0.5rem;}
		#profil .content form .btn_submit input {background-color:$dark_yellow;}
		#profil .content form .btn_submit input:hover, #profil .content form .btn_submit input:focus {border-color: $cim_black;}
	
/********************************************************************************/
/******************************** RESPONSIVE ************************************/
/********************************************************************************/

	@media screen and #{breakpoint(large down)} {}
	
	@media screen and #{breakpoint(medium down)} {
		#etudiant {padding-left:0; padding-top:5rem;}
		#etudiant .titre_bloc {order:0;}
		#etudiant .content {max-width:45rem; margin-right:auto; margin-left:auto;}
		#etudiant #questions {margin-top:5rem; order:2;}
		#etudiant #profil {order:1;}
		#etudiant p:not(.btn_lien), #etudiant h2, #etudiant h3 {text-align:center;}
		#etudiant::before {left:50%; transform:translateX(-50%);}
		#etudiant.bloc_home .titre_bloc h2.bloc_home_title {font-size:2.5em;}
	}
	
	@media screen and #{breakpoint(small down)} {
		#etudiant {padding-bottom:3rem; padding-top:3rem;}
		#etudiant #questions {margin-top:3rem;}
		#etudiant #questions .content {display:none;}
		#etudiant.bloc_home .titre_bloc .bloc_home_title {font-size:2.4em;}
		#etudiant.bloc_home .titre_bloc h2.bloc_home_title {font-size:2em;}
		
		#profil .titre_bloc {margin-bottom:1.5rem;}
		#profil .content .ligne_form {margin-bottom:1.5rem;}
		#profil .content form .btn_submit {padding-top:0;}
	}
